<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'WtgIdpConfirmationDialog',
    props: {
        title: {
            type: String,
            default: 'Are you sure?',
        },
        text: {
            type: String,
            default: 'Confirm action.',
        },
        confirmButtonText: {
            type: String,
            default: 'Confirm',
        },
        show: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        dialog(): boolean {
            return this.show;
        },
    },
});
</script>

<template>
    <wtg-dialog v-model="dialog" persistent max-width="420">
        <wtg-card>
            <wtg-card-title class="bg-red-light">
                <wtg-icon left small color="red">mdi-alert-rhombus-outline</wtg-icon>
                <wtg-label typography="body" font-weight="bold" color="red">
                    {{ title }}
                </wtg-label>
            </wtg-card-title>
            <wtg-divider />
            <wtg-spacer />
            <wtg-card-text>
                <br />
                {{ text }}
            </wtg-card-text>
            <wtg-divider />
            <wtg-card-actions>
                <wtg-btn class="ms-auto" text @click="$emit('cancel')">Cancel</wtg-btn>
                <wtg-btn class="ms-auto" color="red" @click="$emit('confirm')">{{ confirmButtonText }}</wtg-btn>
            </wtg-card-actions>
        </wtg-card>
    </wtg-dialog>
</template>

<style lang="scss">
.bg-red-light {
    background: rgba(255, 0, 0, 0.07);
}
</style>
