export interface AppConfig {
    host: string;
    turnstileSiteKey: string;
    appTitle: string;
}

const config: AppConfig = {
    host: ``,
    turnstileSiteKey: ``,
    appTitle: ``,
};

export default config;
