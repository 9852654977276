import { ApiClient } from '@/apiClient/apiClient';
import { FrontendConfig } from '@/models';

class ConfigApiClient extends ApiClient {
    constructor() {
        super('api/config/');
    }

    public async getFrontendConfig(): Promise<FrontendConfig> {
        return (await this.fetchData('getFrontendConfig', 'GET')) ?? {};
    }
}

export default new ConfigApiClient();
