<script lang="ts">
import { defineComponent } from 'vue';
import WtgIdpLoading from './WtgIdpLoading.vue';
import WtgIdpTermsPrivacy from './WtgIdpTermsPrivacy.vue';

export default defineComponent({
    components: { WtgIdpTermsPrivacy, WtgIdpLoading },
    props: {
        success: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        errorMessage: {
            type: String,
            required: true,
        },
        minHeight: {
            type: String,
            default: '166px',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        showLoginLink: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<template>
    <wtg-container layout="flex" height="100%">
        <wtg-panel fit-to-height class="col-12 col-sm-8">
            <wtg-box class="fill-height d-flex flex-column justify-center">
                <wtg-box
                    v-if="!loading"
                    class="my-auto align-self-center"
                    :min-height="minHeight"
                    max-width="456px"
                    width="90%"
                >
                    <wtg-layout-grid v-if="success">
                        <slot name="success" />
                    </wtg-layout-grid>
                    <wtg-layout-grid v-else>
                        <wtg-spacer />
                        <wtg-label typography="title-large" color="primary">
                            WiseTech Global Identity
                        </wtg-label>
                        <wtg-label typography="title-large">
                            {{ title }}
                        </wtg-label>
                        <slot name="short-description" />
                        <wtg-alert v-if="errorMessage" text outlined dense type="error">
                            {{ errorMessage }}
                            <p v-if="showLoginLink">
                                To login with an existing email, click <router-link to="/">here</router-link>
                            </p>
                        </wtg-alert>
                        <slot />
                    </wtg-layout-grid>
                </wtg-box>
                <wtg-idp-loading v-else x-large color="primary" class="my-auto" />
                <wtg-idp-terms-privacy />
            </wtg-box>
        </wtg-panel>
        <wtg-panel
            style="background-color: rgba(55, 30, 225, 1); border-radius: 16px;"
            class="col-sm-4 d-none d-sm-flex"
            fit-to-height
            height="100%"
        >
            <wtg-image :src="require('../img/panel2.svg')" contain height="100%" />
        </wtg-panel>
    </wtg-container>
</template>
