import { fetchWithDefaults } from 'wtg-idp-shared-components';
import { HeaderNames } from '@/models';

type HttpRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
export class ApiClient {
    private readonly apiUrl: string;
    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }
    async fetchData(
        endpoint: string,
        method: HttpRequestMethod,
        credentials?: RequestCredentials,
        jsonBody?: Record<string, any>,
        queryParams?: Record<string, string>
    ): Promise<any> {
        let response: Response;
        let url = this.apiUrl + endpoint;
        if (queryParams) url += '?' + new URLSearchParams(queryParams).toString();

        try {
            response = await fetchWithDefaults(url, {
                method: method,
                body: jsonBody ? JSON.stringify(jsonBody) : undefined,
                credentials: credentials,
            });
        } catch (error) {
            console.error('Error during fetch', error);
            return await this.redirectToErrorPage();
        }

        if (response.ok) {
            const contentLength = response.headers?.get('Content-Length');
            if (
                response.status === 204 ||
                response.status === 205 ||
                (contentLength && parseInt(contentLength, 10) === 0) ||
                !response.body
            )
                return {};

            const bodyText = await response.text();
            try {
                return JSON.parse(bodyText);
            } catch {
                return { bodyText };
            }
        }

        if (process.env.NODE_ENV === 'development') console.error(response);
        const errorId = response.headers?.get(HeaderNames.ErrorId);
        return await this.redirectToErrorPage(errorId);
    }

    private async redirectToErrorPage(errorId?: string | null): Promise<any> {
        const routerModule = await import('@/router/index');
        await routerModule.default.push(errorId ? { name: 'error', query: { errorId: errorId } } : { name: 'error' });
        return Promise.resolve();
    }
}
