<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'WtgIdpTermsPrivacy',
    computed: {
        computedTermsHref(): string {
            // eslint-disable-next-line no-undef
            const prefixed = __webpack_public_path__ ? __webpack_public_path__ : '/';
            return `${prefixed}terms`;
        },
    },
});
</script>

<template>
    <wtg-box layout="flex">
        <wtg-label class="me-5 d-none d-sm-flex">@2024 WiseTech Global</wtg-label>
        <wtg-label class="me-5 d-flex d-sm-none">@2024 WTG</wtg-label>
        <wtg-label :href="computedTermsHref" target="_blank" class="me-5 text-decoration-underline" color="black">
            Terms of use
        </wtg-label>
        <wtg-label
            href="https://www.wisetechglobal.com/legal/#privacypolicy"
            target="_blank"
            class="me-5 text-decoration-underline"
            color="black"
        >
            Privacy policy
        </wtg-label>
    </wtg-box>
</template>
