import Vue from 'vue';
import WtgMaterialUi, { cargoWiseTheme, components } from 'wtg-material-ui';

Vue.use(WtgMaterialUi, {
    components,
});

export default new WtgMaterialUi({
    theme: cargoWiseTheme,
});
