import Vue from 'vue';
import { CreateElement, VNode } from 'vue/types/umd';
import App from './App.vue';
import config from './config';
import router from './router';
import wtgMaterialUi from './wtgMaterialUi';
import configApiClient from '@/apiClient/configApiClient';

Vue.config.productionTip = false;

async function redirectToErrorPage(message: string) {
    if (router.currentRoute.name !== 'error') await router.push({ name: 'error', params: { message: message } });
}

async function loadConfig() {
    const frontendConfig = await configApiClient.getFrontendConfig();
    config.host = frontendConfig.host;
    config.turnstileSiteKey = frontendConfig.turnstileSiteKey;
    config.appTitle = frontendConfig.appTitle;
}

function setGlobalErrorHandling() {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    Vue.config.errorHandler = async function(err, vm, info) {
        console.error('Vue error:', err);
        await redirectToErrorPage(err.message);
    };
    window.addEventListener('error', async (event) => {
        event.stopImmediatePropagation();
        event.preventDefault();
        console.error('Uncaught error:', event.error);
        await redirectToErrorPage(event.message);
    });
    window.addEventListener('unhandledrejection', async (event) => {
        event.stopImmediatePropagation();
        event.preventDefault();
        if (event.reason.from.name === 'error') return;
        console.error('Unhandled Rejection:', event.reason);
        return await redirectToErrorPage(event.reason.message);
    });
}

loadConfig()
    .then(() => setGlobalErrorHandling())
    .then(() => {
        new Vue({
            router,
            wtgMaterialUi,
            render: (h: CreateElement): VNode => h(App),
        }).$mount('#app');
    });
