<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { PropType } from 'vue/types/umd';

declare global {
    interface Window {
        turnstile?: {
            getResponse: () => Promise<string>;
            render: (
                element: string | HTMLElement,
                options: {
                    sitekey: string;
                    theme: string;
                    size: string;
                    callback?: (token: string) => void;
                    'expired-callback'?: () => void;
                    'error-callback'?: (error: any) => void;
                }
            ) => void;
            reset: () => void;
        };
        onloadTurnstileCallback?: () => void;
    }
}

export default defineComponent({
    name: 'TurnstileComponent',
    props: {
        sitekey: {
            type: String,
            required: true,
        },
        theme: {
            type: String as PropType<'light' | 'dark' | 'auto'>,
            required: false,
            default: 'light',
        },
        size: {
            type: String as PropType<'normal' | 'compact'>,
            required: false,
            default: 'normal',
        },
    },
    emits: {
        verify: (response: string) => {
            if (response !== null && response !== '') return true;
            else return false;
        },
        expire: null,
        fail: null,
    },
    setup(props, context) {
        onMounted(() => {
            if (window.turnstile === null || !window.turnstile) {
                const script = document.createElement('script');
                script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);
            } else {
                renderTurnstile();
            }
            onloadTurnstileCallback();
        });

        function onloadTurnstileCallback() {
            window.onloadTurnstileCallback = () => {
                renderTurnstile();
            };
        }

        function renderTurnstile() {
            window.turnstile?.render('#turnstile-box', {
                sitekey: props.sitekey,
                theme: props.theme,
                size: props.size,
                callback: (response: string) => context.emit('verify', response),
                'expired-callback': () => context.emit('expire'),
                'error-callback': (error: any) => context.emit('fail', error),
            });
        }
    },
});
</script>

<template>
    <div id="turnstile-box" data-theme="light" />
</template>

<style scoped></style>
