<template>
    <wtg-app>
        <wtg-main>
            <router-view />
        </wtg-main>
    </wtg-app>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'App',
});
</script>
