<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'WtgIdpLoading',
});
</script>

<template>
    <wtg-icon v-bind="[$attrs]" class="wtg-loader">mdi-loading</wtg-icon>
</template>

<style lang="scss">
.wtg-loader {
    animation: rotation infinite 3s linear;
    display: flex;
    align-items: center;
    position: absolute;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
