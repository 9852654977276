import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

let consentGiven = false;
export const setConsentStatus = (status: boolean): void => {
    consentGiven = status;
};
export const getConsentStatus = (): boolean => {
    return consentGiven;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout.vue'),
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register.vue'),
    },
    {
        path: '/sendVerificationEmail',
        name: 'sendVerificationEmail',
        component: () => import('@/views/SendVerificationEmail.vue'),
    },
    {
        path: '/verify',
        name: 'verify',
        component: () => import('@/views/Verify.vue'),
    },
    {
        path: '/sendRecoveryEmail',
        name: 'sendRecoveryEmail',
        component: () => import('@/views/SendRecoveryEmail.vue'),
    },
    {
        path: '/recovery',
        name: 'recovery',
        component: () => import('@/views/Recovery.vue'),
    },
    {
        path: '/addNewPasskey',
        name: 'addNewPasskey',
        component: () => import('@/views/AddNewPasskey.vue'),
    },
    {
        path: '/consent',
        name: 'consent',
        component: () => import('@/views/Consent.vue'),
    },
    {
        path: '/confirmUser',
        name: 'confirmUser',
        component: () => import('@/views/ConfirmUser.vue'),
        beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
            if (!getConsentStatus()) next({ name: 'consent', query: to.query });
            else next();
        },
    },
    {
        path: '/terms',
        name: 'Terms of use',
        beforeEnter(): void {
            // eslint-disable-next-line no-undef
            window.location.href = `${__webpack_public_path__}legal/Terms.pdf`;
        },
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('@/views/Error.vue'),
        props: true,
    },
    {
        path: '*',
        name: 'Not Found',
        component: () => import('@/views/Error.vue'),
        props: {
            message:
                "The page you're looking for doesn't exist. " +
                'It might have been moved, deleted or the URL was mistyped. ' +
                'If you think this is a mistake, please contact your system administrator.',
        },
    },
];
