/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* global __webpack_public_path__*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import config from '@/config';
import { routes } from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: __webpack_public_path__,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    document.title = config.appTitle;
    next();
});

export default router;
