/* global __webpack_public_path__*/
export default async function fetchWithDefaults(url: string, options: RequestInit = {}): Promise<Response> {
    const mergedOptions = buildMergedOptions(options);

    return fetch(__webpack_public_path__ + url, mergedOptions);
}

function buildMergedOptions(options: RequestInit) {
    const defaultOptions: RequestInit = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        credentials: 'include',
    };

    const mergedOptions = {
        ...defaultOptions,
        ...options,
        headers: {
            ...defaultOptions.headers,
            ...options.headers,
        },
    };
    return mergedOptions;
}
