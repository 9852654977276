<script lang="ts">
import { defineComponent } from 'vue';
import WtgIdpLoading from './WtgIdpLoading.vue';
import WtgIdpTermsPrivacy from './WtgIdpTermsPrivacy.vue';

export default defineComponent({
    components: { WtgIdpTermsPrivacy, WtgIdpLoading },
    props: {
        textCenter: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<template>
    <wtg-container class="fill-height d-flex flex-column justify-center align-center" max-width="651px">
        <wtg-card v-show="!loading" max-width="90%" class="my-auto">
            <wtg-box :class="[{ 'text-center': textCenter }, 'mx-auto']" width="90%">
                <wtg-layout-grid>
                    <wtg-spacer />
                    <wtg-label typography="title-large" color="primary">
                        WiseTech Global Identity
                    </wtg-label>
                    <wtg-spacer />
                    <wtg-label v-if="title" typography="title-large">
                        {{ title }}
                    </wtg-label>
                    <slot />
                </wtg-layout-grid>
            </wtg-box>
        </wtg-card>
        <wtg-idp-loading v-show="loading" x-large color="primary" class="my-auto" />
        <wtg-idp-terms-privacy />
    </wtg-container>
</template>
